<template>
	<!-- 提现 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<router-link class="btn" to="/renew">{{$t('aside.renew')}}</router-link>
				<i class="iconfont2 icon-right_arrow"></i>
				<span>{{$t('common.withdrawal')}}</span>
			</div>
			
			<div class="content">
				<div class="recharge_box">
					<div class="row_top">
						<div class="row_left">
							<div class="recharge_account">
								<div class="caption"><span class="icon"><img src="@/assets/images/icon-text.png" /></span> USDT</div>
								<div class="db">
									<div class="item">
										<div class="title">{{rechargeOptions[radioValue].name+$t('renew.subBalance')}}</div>
										<div class="text">{{rechargeOptions[radioValue].balance}}</div>
									</div>
									<!-- <div class="item" v-if="rechargeOptions[radioValue].name!='light-wallet'">
										<div class="title">{{rechargeOptions[radioValue].name+$t('renew.address')}}</div>
										<div class="text">{{addressFormat(rechargeOptions[radioValue].address)}}</div>
									</div> -->
									<div class="item">
										<div class="title">{{$t('renew.min')}}</div>
										<div class="text">50.00</div>
									</div>
									<div class="item">
										<div class="title">{{$t('renew.service')}}</div>
										<div class="text">0</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="row_right">
							<div class="recharge_form">
								<div class="bd">
									<div class="item withdrawalType">
										<span class="title">{{$t('renew.withdrawalType')}}</span>
										<div class="text">
											<el-radio-group
											@change="typeChange"
											v-model="radioValue">
												<template
													v-for="(item, index) in rechargeOptions">

													<el-radio-button
														:key="index"
														:label="index"

														v-if="item.name=='light-wallet'"
													>{{ item.name }}</el-radio-button>
													
												</template>
											</el-radio-group>
										</div>
									</div>

									<div class="item">
										<div class="title">{{$t('renew.withdrawalAmount')}}</div>
										<div class="text">
											<input
												class="inp"
												:placeholder="$t('renew.withdrawalHolder')"
												type="text"
												v-model="cashValue"
											/>
											<span class="btn g_btn" @click="handleAllcash">{{$t('common.whole')}}</span>
										</div>
									</div>
									<div class="item" v-if="rechargeOptions[radioValue].name=='light-wallet'">
										<div class="title">{{$t('renew.withdrawalChain')}}</div>
										<div class="text">
											<el-select
												class="selectInp" 
												v-model="chain" 
												:placeholder="$t('common.select')">
												<template v-for="(item,index) in rechargeOptions">
													<el-option
													v-if="item.name!='light-wallet'"
													:key="index"
													:label="item.name.toUpperCase()"
													:value="item.address_type">
													</el-option>
												</template>
											</el-select>
											<!-- <input
												v-model="urlValue" type="text" :placeholder="$t('common.input')" class="inp" /> -->
										</div>
									</div>
									<div class="item">
										<div class="title">{{$t('renew.withdrawalAddress')}}</div>
										<div class="text">
											<input
												v-model="urlValue" type="text" :placeholder="$t('common.input')" class="inp" />
										</div>
									</div>

									<div class="item">
										<span class="title">{{$t('common.verification')}}</span>
										<div class="text">
											<input
												v-model="code" type="text" class="inp" :placeholder="$t('common.inputVerification')" />
											<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
											<div v-else class="g_btn btn" @click="handleSendCode">
												<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
												<template v-else>{{$t('common.resend')}}</template>
											</div>
										</div>
									</div>
									
									<div class="submit">
										<div class="btn" @click="handleConfirm">{{$t('common.submit')}}</div>
									</div>

									<div class="tip">
										<div class="text">
											<div>{{$t('renew.tip')}}</div>
											<p v-if="!!chain&&rechargeOptions[chain].name!='light-wallet'">
												{{$t('renew.withdrawalTip1',{type:rechargeOptions[chain].name.toUpperCase()})}}
											</p>
											<p v-else>
												{{$t('renew.withdrawalTip2')}}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row_bottom">
						<div class="recharge_record">
							<div class="head">
								<i class="iconfont icon-record"></i>
								{{$t('renew.withdrawalRecord')}}
								<span>{{$t('renew.only5')}}</span>
							</div>
							<div class="list">
								<div class="hd">
									<div class="addr">{{$t('renew.withdrawalAddress')}}</div>
									<div class="price">{{$t('renew.withdrawalAmount')}}</div>
									<div class="type">{{$t('renew.withdrawalType')}}</div>
									<div class="time">{{$t('renew.withdrawalTime')}}</div>
									<div class="state">{{$t('renew.currentState')}}</div>
									<div class="jump"></div>
								</div>
								<div class="bd">
									<div class="bd_box">
										<template v-if="rechargeList.length">
											<div class="item" v-for="(item,index) in rechargeList" :key="index">
												<div class="addr">
													<template v-if="item.address">
														<el-tooltip effect="dark" :content="$t('subManage.copyAddress')" placement="top" :enterable="false">
															<i class="iconfont2 icon-jilu1 copyBtn" @click="handleCopy(item.address)"></i>
														</el-tooltip>
														<el-tooltip effect="dark" :content="item.address" placement="top" :enterable="false">
															<span>{{addressFormat(item.address)}}</span>
														</el-tooltip>
													</template>
												</div>
												<div class="price">{{item.num}} USDT</div>
												<div class="type">{{
													item.trade_type==1?'BEP20':
													item.trade_type==2?'TRC20':
													item.trade_type==3?'light-wallet':item.trade_type
												}}</div>
												<div class="time">{{item.time}}</div>
												<div class="state">
													{{item.state?$t('state')[item.state]:item.state}}
												</div>
												<div class="jump">
													<i class="iconfont2 icon-tiaozhuan" v-if="item.state==8" @click="jump(item.tx,item.trade_type)"></i>
												</div>
											</div>
										</template>
										<el-empty v-else :image="emptyImg" :image-size="120" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="recharge_box">
					<div class="row_top">
						<div class="recharge_form">
							<div class="caption"><span class="icon"><img src="@/assets/images/icon-text.png" /></span> USDT</div>
							<div class="bd">
								<div class="item">
									<div class="title">{{$t('renew.withdrawalType')}}</div>
									<div class="text">
										<el-radio-group
										@change="typeChange"
										v-model="radioValue">
											<template
												v-for="(item, index) in rechargeOptions">
												<el-radio-button
													:key="index"
													:label="index"
													v-if="item.name=='light-wallet'"
												>{{ item.name }}</el-radio-button>
											</template>
										</el-radio-group>
									</div>
								</div>
								<div class="item">
									<div class="title">{{$t('renew.withdrawalAmount')}}</div>
									<div class="text">
										<input
											class="inp"
											:placeholder="$t('renew.withdrawalHolder')"
											type="text"
											v-model="cashValue"
										/>
										<span class="btn g_btn" @click="handleAllcash">{{$t('common.whole')}}</span>
									</div>
								</div>
								<div class="item" v-if="rechargeOptions[radioValue].name=='light-wallet'">
									<div class="title">{{$t('renew.withdrawalChain')}}</div>
									<div class="text">
										<el-select
											class="selectInp" 
											v-model="chain" 
											:placeholder="$t('common.select')">
											<template v-for="(item,index) in rechargeOptions">
												<el-option
												v-if="item.name!='light-wallet'"
												:key="index"
												:label="item.name.toUpperCase()"
												:value="item.address_type">
												</el-option>
											</template>
										</el-select>
									</div>
								</div>
								<div class="item">
									<div class="title">{{$t('renew.withdrawalAddress')}}</div>
									<div class="text">
										<input
											v-model="urlValue" type="text" :placeholder="$t('common.input')" class="inp" />
									</div>
								</div>
								<div class="item">
									<div class="title">{{$t('common.verification')}}</div>
									<div class="text">
										<input
											v-model="code" type="text" class="inp" :placeholder="$t('common.inputVerification')" />
										<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
										<div v-else class="g_btn btn" @click="handleSendCode">
											<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
											<template v-else>{{$t('common.resend')}}</template>
										</div>
									</div>
								</div>
							</div>

							<!-- 资产 -->
							<div class="recharge_account">
								<div class="item">
									<div class="title">{{rechargeOptions[radioValue].name+$t('renew.subBalance')}}</div>
									<div class="text">{{rechargeOptions[radioValue].balance}}</div>
								</div>
								<!-- <div class="item" v-if="rechargeOptions[radioValue].name!='light-wallet'">
									<div class="title">{{rechargeOptions[radioValue].name+$t('renew.address')}}</div>
									<div class="text">{{addressFormat(rechargeOptions[radioValue].address)}}</div>
								</div> -->
								<div class="item">
									<div class="title">{{$t('renew.min')}}</div>
									<div class="text">50.00</div>
								</div>
								<div class="item">
									<div class="title">{{$t('renew.service')}}</div>
									<div class="text">0</div>
								</div>
								<div class="submit">
									<div class="btn" @click="handleConfirm">{{$t('common.submit')}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row_bottom">
						<div class="tip">
							<div class="text">
								<div>{{$t('renew.tip')}}</div>
								<p v-if="!!chain&&rechargeOptions[chain].name!='light-wallet'">
									{{$t('renew.withdrawalTip1',{type:rechargeOptions[chain].name.toUpperCase()})}}
								</p>
								<p v-else>
									{{$t('renew.withdrawalTip2')}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'withdrawal.styl';
</style>
<script>
	import { Loading } from 'element-ui';
	import Clipboard from 'clipboard';
	
	var timer;
	import {mapGetters} from 'vuex'
	
	import addressFormat from '@/utils/methApi/addressFormat'
	// api
	/**
	 * getRechargePath 获取对应钱包余额
	 * sendEmail 发送验证码
	 * withdraw 提现
	 * getWithdrawRecord 提现记录
	 */
	import {
		getRechargePath,
		sendEmail,
		withdraw,
		getWithdrawRecord,
	} from '@/api/user'
	export default ({
		name: 'recharge',
		data() {
			return {
				radioValue: 0,
				cashValue:'',
				chain:'',
				urlValue:'',
				code:'',
				emptyImg: require('@/assets/images/norecord.png'),
				
				codeSending: false,
				timeCount: 60,
				firstSend: true,

				rechargeOptions: [
					{
						name: 'light-wallet',
						type: 'light-wallet',
						balance:'',
					},
					{
						name: 'BEP20',
						type: 'bep20',
						balance:'',
					},
					{
						name: 'TRC20',
						type: 'trc20',
						balance:'',
					}
				],
				// 此处数据仅用于测试,请自行设置数据名称
				rechargeList: [],
			}
		},
		computed:{
			...mapGetters(['account','isMobile','email',])
		},
		created() {
			const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
			if(this.isMobile!=1){
				// 获取提现记录
				Promise.all([this.getRechargePath(),this.getList()]).then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			}else{
				// 充值地址及余额
				this.getRechargePath().then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			}
		},
		methods: {
			handleCopy(code) {
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			// 提现链切换
			typeChange(){
				// if(this.rechargeOptions[this.radioValue].name=='light-wallet'){
					this.cashValue=''
					this.urlValue=''
					this.code=''
				// }
			},
			addressFormat(address){
				if(address){
					return addressFormat(address)
				}
			},
			// 获取钱包余额
			async getRechargePath(){
				try{
					let res=await getRechargePath({account:this.account})
					// console.log(res)
					var rechargeOptions=[]
					var wallet_list=res.wallet_list
					for(var key in wallet_list){
						var name= key=='light_wallet'?'light-wallet':key;
						var obj={
							name: name,
							type: name,
							balance: wallet_list[key].balance,
							address:wallet_list[key].address,
							address_type:wallet_list[key].type
						}
						rechargeOptions.push(obj)
					}
					this.rechargeOptions=rechargeOptions
				}catch(err){
					if(err.code==2176){
        				let message = (err.error && err.error.message) || err.message || err.msg ||this.$t('tip.unknownErr');
						this.$confirm(message, this.$t('tip.tips'), {
							confirmButtonText: this.$t('common.confirm'),
							center: true,
							showClose: false,
							customClass: 'deleteOk',
							showCancelButton:false,
						}).then(()=>{
							this.$router.push({
								path:'/renew',
							})
						});
					}
				}
			},
			// 获取提现记录
			async getList(){
				let res = await getWithdrawRecord({account:this.account})
				this.rechargeList=res;
			},
			// 全部提现
			handleAllcash() {
				// if(this.rechargeOptions[this.radioValue].name!='light-wallet'){
					this.cashValue = this.rechargeOptions[this.radioValue].balance;
				// }
			},
			// 发送验证码
			handleSendCode() {
				// if(this.rechargeOptions[this.radioValue].name!='light-wallet'){
					if(!this.codeSending){
						// 发送验证码
						this.timeCount = 60;
						this.codeSending = true;
						let that = this;
						this.firstSend = false;
						clearInterval(timer);
						timer = setInterval(() => {
							if(this.timeCount >= 1) {
								that.timeCount -= 1;
							} else {
								clearInterval(timer);
								this.codeSending = false;
							}
						}, 1000);
						
						sendEmail({
							account:this.account,
							email:this.email,
							type:1,
						})
					}
				// }
			},
			// 提现
			handleConfirm(){
				// console.log(this.rechargeOptions,this.chain)
				// if(this.rechargeOptions[this.radioValue].name!='light-wallet'){
					if(!this.cashValue||this.cashValue<50){
						this.$message({
							message: this.$t('tip.withdrawRestrictions'),
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else if(!this.urlValue){
						this.$message({
							message: this.$t('tip.addressEmpty'),
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else if(!this.code){
						this.$message({
							message: this.$t('tip.VerificationEmpty'),
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						withdraw({
							account:this.account,
							num:this.cashValue,
							address:this.chain+'_'+this.urlValue,
							wallet_type:this.rechargeOptions[this.radioValue].address_type,
							sms_code:this.code
						}).then(res=>{
							if(res==1){
								// 重新获取提现记录
								this.getList();
								// 重新获取余额
								this.getRechargePath();
								// 提现成功
								this.$confirm(this.$t('tip.withdrawalSuc'), this.$t('tip.tips'), {
									confirmButtonText: this.$t('common.confirm'),
									center: true,
									showClose: false,
									customClass: 'deleteOk',
									showCancelButton:false,
								});
								// this.$message({
								// 	message: this.$t('tip.withdrawalSuc'),
								// 	type: 'success',
								// 	center: true,
								// 	offset: 100,
								// 	customClass: 'shotMsg',
								// });
								this.cashValue = null;
								this.urlValue = null;
								this.code = null;
							}
						})
					}
				// }
			},
			// 区块查询
			jump(path,type){
				// if(type==3){
				// 	// 如果是闪电钱包 提示不支持查看
				// 	this.$message({
				// 		message: this.$t('tip.notSupported'),
				// 		type: 'info',
				// 		center: true,
				// 		customClass: 'shotMsg',
				// 	});
				// }else 
				if(!!path){
					// window.location.href=path
					window.open(path)
				}else{
					this.$message({
						message: this.$t('tip.txSeachErr'),
						type: 'info',
						center: true,
						customClass: 'shotMsg',
					});
				}
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
		}
	})
</script>
